@import 'antd/dist/reset.css';

body {
  font-size: 16px;
  background-color: #f0f2f5;
}

.ant-layout-header {
  background: #fff;
  padding: 0;
  height: auto;
  min-height: 64px;

  border-bottom: 1px solid #e0e0e0;
}

.ant-layout-content {
  background-color: rgb(249, 250, 251);
  min-height: 600px;
}

.ant-menu-horizontal {
  border: none;
}

.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(107, 114, 128, 1);
  font-weight: 500;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: rgba(17, 24, 39, 1);
}


.menu-card {
  @apply flex flex-col justify-center items-center bg-white h-screen py-40;
}

.center-content {
  @apply flex flex-col justify-center items-center;
}


.space-align-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.space-align-block {
  flex: none;
  margin: 8px 4px;
  padding: 4px;
  border: none;
}
.space-align-block .mock-block {
  display: inline-block;
  padding: 32px 8px 16px;
  background: rgba(150, 150, 150, 0.2);
}

.bg-indigo-600{
  background-color: rgb(79, 70, 229);
}

.card-table-no-placeholder .ant-table-placeholder{
  display: none;
}

.card-table .ant-pagination {
  padding: 0 16px;
}

.card-table .ant-empty {
  height: 200px;
}

.card-table .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before  {
  box-shadow: none;
}
.card-table .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none;
}

.select-rows-per-page-en .ant-select-selector::before {
  content: 'Rows per page';
  width: 104px;
  align-self: center;
}

.nb-code {
  margin-bottom: 1em;
}

.nb-code pre {
  margin-bottom: 0;
}

.nb-code code {
  border-radius: .25em;
}

.ant-steps-item-title {
  color: rgba(0, 0, 0, .85) !important;
}

.access-control-table .tooltip-label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.access-control.input-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.access-control.ant-drawer-subtitle {
  line-height: 22px;
  margin: 24px 0;
}

.ant-steps-item-icon{
  background: #EBEBEB !important;
  border-color: #EBEBEB !important;
}
.ant-steps-icon-dot{
  background: #EBEBEB !important;
  border-color: #EBEBEB !important;
}
.ant-steps-icon  {
  background: #EBEBEB !important;
  border: none;
  color: black !important;
}

.ant-steps-item-tail::after {
  background: #EBEBEB !important;
}

.ant-steps-item-tail {
  border: none;
}

td.non-highlighted-table-column {
  background-color: #FFFFFF !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #FAFAFA !important;
}

.ant-table-thead .ant-table-cell {
  font-weight: normal !important;
}